import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Prismic from '@prismicio/client';
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';

import { PRISMIC_ENDPOINT } from './Constant';
import Home from './Home';
import HomeStudio from './HomeStudio';
import Page from './Page';

import './scss/App.scss';
import Contact from './Contact';

const App = () => {
  const [home, setHome] = useState({ data: {} });
  const client = Prismic.client(PRISMIC_ENDPOINT);
  useEffect(async () => {
    const entry = await client.getByUID('home', 'home');
    setHome(entry);
  }, []);
  const [navById, setNavById] = useState({});
  useEffect(async () => {
    const navIds = [];
    if (home.id !== undefined) {
      home.data.home_navs.forEach((row) => {
        navIds.push(row.home_nav.id);
      });
      navIds.push(home.data.studio_nav.id);
      const entries = await client.getByIDs(navIds);
      const newNavById = {};
      entries.results.forEach((entry) => {
        newNavById[entry.id] = entry.data;
      });
      setNavById(newNavById);
    }
  }, [home]);
  if (home.uid === undefined) return <div>Loading...</div>;
  // console.log(home.data.logo);
  return (
    <div className="App">
      <Helmet>
        <title>{RichText.asText(home.data.site_name)}</title>
        <meta property="og:site_name" content={RichText.asText(home.data.site_name)} />
        <meta property="og:title" content={RichText.asText(home.data.site_name)} />
        <meta property="og:description" content={RichText.asText(home.data.meta_description)} />
        <meta property="og:image" content={home.data.home_background.url} />
      </Helmet>
      <Switch>
        <Route path="/studio"><HomeStudio home={home} navById={navById} /></Route>
        <Route path="/page/:uid"><Page logo={home.data.logo} /></Route>
        <Route path="/contact"><Contact logo={home.data.logo} /></Route>
        <Route path="/"><Home home={home} navById={navById} /></Route>
      </Switch>
    </div>
  );
};

export default App;
