import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

const Home = ({ home, navById }) => {
  if (home.id === undefined) return (<div>Loading</div>);
  const { data } = home;
  return (
    <div
      className="home"
      style={{ backgroundImage: `url('${data.home_background.url}')` }}
    >
      <h1>{RichText.asText(data.site_name)}</h1>
      {data.home_navs.map((nav) => (
        <React.Fragment key={nav.home_nav.id}>
          {navById[nav.home_nav.id] !== undefined ? (
            <div className="nav-group">
              <div className="level-1">
                <div className="circle">
                  <Link to={navById[nav.home_nav.id].page.uid ? `/page/${navById[nav.home_nav.id].page.uid}` : navById[nav.home_nav.id].url}>
                    <img src={nav.icon.url} alt="our story" />
                    {navById[nav.home_nav.id].label}
                  </Link>
                </div>
              </div>
              {navById[nav.home_nav.id].sub_nav.map((row) => (
                <div className="level-2" key={row.sub_label}>
                  <div className="circle">
                    <Link to={row.sub_page.uid !== undefined ? `/page/${row.sub_page.uid}` : row.sub_url}>
                      {row.sub_label}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
};
Home.propTypes = {
  home: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.shape({
      site_name: PropTypes.arrayOf(PropTypes.object),
      home_navs: PropTypes.arrayOf(PropTypes.object),
      studio_nav: PropTypes.shape({
        id: PropTypes.string,
      }),
      home_background: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }).isRequired,
  navById: PropTypes.shape().isRequired,
};

export default Home;
