/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const HomeStudio = ({ home, navById }) => {
  if (home.id === undefined) return (<div>Loading</div>);
  const { data } = home;
  const studioTitle = 'Bethany’s Studio of Arts';
  return (
    <div
      className="home-studio"
      style={{ backgroundImage: `url('${data.studio_background.url}')` }}
    >
      <Helmet>
        <title>{studioTitle}</title>
        <meta property="og:title" content={studioTitle} />
        <meta property="og:description" content={RichText.asText(home.data.meta_description_studio)} />
        <meta property="og:image" content={data.studio_background.url} />
      </Helmet>
      <div className="background-wrapper">
        <Container>
          <Row>
            <Col md="4">
              <div className="h1-circle">
                <h1>{studioTitle}</h1>
              </div>
            </Col>
            <Col>
              <Row>
                {data.class_description.map((row, i) => (
                  <Col key={i}>
                    {RichText.render(row.description)}
                  </Col>
                ))}
              </Row>
              <Row>
                <Col>
                  <Link to={data.action_url} className="as-button">{data.action_label}</Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          {navById[data.studio_nav.id] !== undefined ? (
            <Col md="4" className="studio-nav">
              {navById[data.studio_nav.id].sub_nav.map((row, i) => (
                <div className="circle" key={i}>
                  <Link to={row.sub_page.uid !== undefined ? `/page/${row.sub_page.uid}` : row.sub_url}>
                    {row.sub_label}
                  </Link>
                </div>
              ))}
            </Col>
          ) : null }
        </Row>
      </Container>
    </div>
  );
};

HomeStudio.propTypes = {
  home: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.shape({
      studio_background: PropTypes.shape({
        url: PropTypes.string,
      }),
      studio_nav: PropTypes.shape({
        id: PropTypes.string,
      }),
      class_description: PropTypes.arrayOf(PropTypes.object),
      meta_description_studio: PropTypes.arrayOf(PropTypes.object),
      action_url: PropTypes.string,
      action_label: PropTypes.string,
    }),
  }).isRequired,
  navById: PropTypes.shape().isRequired,
};

export default HomeStudio;
