/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Prismic from '@prismicio/client';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { PRISMIC_ENDPOINT } from './Constant';

const Contact = ({ logo }) => {
  const [entry, setEntry] = useState({ data: {} });
  const client = Prismic.client(PRISMIC_ENDPOINT);

  useEffect(async () => {
    const newEntry = await client.getByUID('contact', 'contact');
    setEntry(newEntry);
  }, []);

  if (entry.id === undefined) return (<div>Loading</div>);
  const { data } = entry;
  console.log(data);
  return (
    <div className="contact">
      <Container>
        <Row>
          <Col lg={8}>
            <div className="logo">
              <Link to="/">
                <img src={logo.url} alt="logo" />
              </Link>
            </div>
            <div className="main-content">
              <h1>{RichText.asText(data.title)}</h1>
              {RichText.render(data.body)}
              <form action="https://usebasin.com/f/05477214b955" method="POST">
                <Row>
                  <Col md={4}>
                    <label>Your Name</label>
                    <input name="name" type="text" required />
                  </Col>
                  <Col md={4}>
                    <label>Your Email</label>
                    <input name="email" type="email" required />
                  </Col>
                  <Col md={4}>
                    <label>Your Phone Number</label>
                    <input name="phone" type="text" />
                  </Col>
                </Row>
                <label>Subject</label>
                <input name="subject" type="text" />
                <input name="honey" type="hidden" />
                <label>Message</label>
                <textarea name="message" />
                <Button type="submit">Submit</Button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
Contact.propTypes = {
  logo: PropTypes.shape({ url: PropTypes.string }),
};
Contact.defaultProps = {
  logo: {},
};

export default Contact;
