/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row, Modal,
} from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import Prismic from '@prismicio/client';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Helmet } from 'react-helmet';

import { PRISMIC_ENDPOINT } from './Constant';

const Page = ({ logo }) => {
  const client = Prismic.client(PRISMIC_ENDPOINT);
  const [lightboxImage, setLightboxImage] = useState({ src: '', caption: '' });
  const [page, setPage] = useState({});
  const [show, setShow] = useState(false);
  const [navGroup, setNavGroup] = useState({ data: { navs: [] } });
  const { uid } = useParams();
  const [navById, setNavById] = useState({});
  useEffect(async () => {
    const entry = await client.getByUID('page', uid);
    // console.log(entry);
    setPage(entry);
  }, [uid]);
  useEffect(async () => {
    if (page.uid !== undefined) {
      if (navGroup.uid !== page.data.nav_group.uid) {
        const newNavGroup = await client.getByUID('nav_group', page.data.nav_group.uid);
        setNavGroup(newNavGroup);
        // console.log(navGroup);
        const navIds = [];
        newNavGroup.data.navs.forEach((nav) => {
          navIds.push(nav.nav.id);
        });
        const entries = await client.getByIDs(navIds);
        const newNavById = {};
        entries.results.forEach((entry) => {
          newNavById[entry.id] = entry.data;
        });
        setNavById(newNavById);
      }
    }
  }, [page]);
  const location = useLocation();
  useEffect(() => { setShow(false); }, [location]);
  if (page.uid === undefined) return (<div>Loading...</div>);
  const { data } = page;
  // console.log(logo);
  const toggleMenu = () => {
    setShow(!show);
  };
  const lightboxOnHide = () => {
    setLightboxImage({ src: '', caption: '' });
  };
  // console.log(data);
  return (
    <div className="page">
      <Container>
        <Row>
          <Col lg="3">
            <div className="logo">
              <Link to="/">
                <img src={logo.url} alt="logo" />
              </Link>
              <button type="button" className="hamburger" onClick={toggleMenu}>
                <span className="bar" />
                <span className="bar" />
                <span className="bar" />
              </button>
            </div>
            <ul className={`page-nav ${show ? 'show' : 'hide'}`}>
              {navGroup.data.navs.map((nav) => (
                <Nav nav={navById[nav.nav.id]} key={nav.nav.id} />
              ))}
            </ul>
          </Col>
          <Col>
            <div className="main-content">
              <Helmet>
                <title>{RichText.asText(data.title)}</title>
                <meta property="og:title" content={RichText.asText(data.title)} />
                {RichText.asText(data.meta_description) !== '' ? (
                  <meta property="og:description" content={RichText.asText(data.meta_description)} />
                ) : null}
                {data.meta_image.url !== undefined ? (
                  <meta property="og:image" content={data.meta_image.url} />
                ) : null}
              </Helmet>
              <h1>{RichText.asText(data.title)}</h1>
              {data.body.map((slice, i) => (
                <Slice
                  slice={slice}
                  key={i}
                  setLightboxImage={setLightboxImage}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={lightboxImage.src !== ''}
        onHide={lightboxOnHide}
        className="modal-page-image"
        size="lg"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <img
            src={`${lightboxImage.src}&height=550`}
            alt={lightboxImage.caption !== '' ? lightboxImage.caption : 'gallery'}
          />
        </Modal.Body>
        {lightboxImage.caption !== '' ? (
          <Modal.Footer>
            {lightboxImage.caption}
          </Modal.Footer>
        ) : null}
      </Modal>
    </div>
  );
};
Page.propTypes = {
  logo: PropTypes.shape({ url: PropTypes.string }),
};
Page.defaultProps = {
  logo: {},
};
const Nav = ({ nav }) => {
  if (nav === undefined) return (<div>loading</div>);
  const location = useLocation();
  let className = '';
  if (location.pathname === nav.url) className = 'active';
  if (location.pathname === `/page/${nav.page.uid}`) className = 'active';
  const navTree = {
    label: nav.label,
    url: nav.page.uid !== undefined ? `/page/${nav.page.uid}` : nav.url,
    sub: [],
  };
  nav.sub_nav.forEach((sub) => {
    const url = sub.sub_page.uid !== undefined ? `/page/${sub.sub_page.uid}` : sub.sub_url;
    const label = sub.sub_label;
    navTree.sub.push({
      label,
      url,
    });
    if (location.pathname === url) className = 'active';
  });
  return (
    <li className={className}>
      <Link to={navTree.url}>
        {navTree.label}
      </Link>
      {navTree.sub.length > 0 ? (
        <ul>
          {navTree.sub.map((sub) => (
            <li key={sub.label}>
              <Link to={sub.url}>
                {sub.label}
              </Link>
            </li>
          ))}
        </ul>
      ) : null }
    </li>
  );
};
Nav.propTypes = {
  nav: PropTypes.shape({
    page: PropTypes.shape({
      uid: PropTypes.string,
    }),
    url: PropTypes.string,
    label: PropTypes.string,
    sub_nav: PropTypes.arrayOf(PropTypes.object),
  }),
};
Nav.defaultProps = {
  nav: {
    url: '/',
    page: {},
    sub_nav: [],
  },
};

const Slice = ({ slice, setLightboxImage }) => {
  // console.log(slice);
  const imageInfo = [];
  switch (slice.slice_type) {
    case 'image':
      return (<img src={slice.primary.image.url} alt="slice" className="image-insert" />);
    case 'text':
      return (<div>{RichText.render(slice.primary.text)}</div>);
    case 'gallery':
      slice.items.forEach((item, i) => {
        let alt = 'Gallery';
        let caption = '';
        // console.log(item)
        if (item.image.alt !== null && item.image.alt !== '') {
          alt = item.image.alt;
          caption = item.image.alt;
        }
        imageInfo[i] = { alt, caption };
      });
      return (
        <ResponsiveMasonry>
          <Masonry className="wall">
            {slice.items.map((item, i) => (
              <div className="brick" key={item.image.url}>
                <button
                  type="button"
                  onClick={() => setLightboxImage({
                    src: item.image.url,
                    caption: imageInfo[i].caption,
                  })}
                >
                  <img
                    src={`${item.image.url}&w=350`}
                    alt={imageInfo[i].alt}
                  />
                </button>
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      );
    default:
      return (<div>{slice.slice_type}</div>);
  }
};
Slice.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    primary: PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      text: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  setLightboxImage: PropTypes.func,
};
Slice.defaultProps = {
  setLightboxImage: () => {},
};
export default Page;
